import NextLink from "next/link"
import { Box, Center, Heading, Link, Text } from "@chakra-ui/layout"
import { useTranslations } from "use-intl"
import { PageSEO } from "@components/HeadSEO"

const PageNotFound = (): JSX.Element => {
  const t = useTranslations("page404")

  return (
    <>
      <PageSEO
        title={String(t("pageTitle"))}
        description={String(t("pageDescription"))}
      />
      <Center>
        <Box mt={"20vh"} textAlign="center">
          <Heading variant="contentHeading1" mb="4">
            {t("title")}
          </Heading>
          <Text>
            {t("description")}{" "}
            <NextLink href={String(t("backUrl"))} passHref>
              <Link>{t("backLabel")}</Link>
            </NextLink>
          </Text>
        </Box>
      </Center>
    </>
  )
}

export default PageNotFound
